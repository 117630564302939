import { Sentry } from "../sentryInstrument";

const cartAmountContainers = document.querySelectorAll(".cart_amount");

export function updateCartIcons(itemsCount) {
  if(itemsCount !== null) {
    const floatingShoppingBag = document.getElementById("floating_shopping_cart");
    if (floatingShoppingBag) {
      if (itemsCount > 0) {
        floatingShoppingBag.classList.remove("no-products");
      } else {
        floatingShoppingBag.classList.add("no-products");
      }
    }

    let shoppingBagHTML = "";
    if (itemsCount > 0) {
      shoppingBagHTML = `<span class="cart-items-amount">${itemsCount}</span>`;
    } 
    
    cartAmountContainers.forEach(item => {
      item.innerHTML = shoppingBagHTML;
    });
  }
}

export async function updateCartIconsAsync(retriesLeft) {
  if (cartAmountContainers.length > 0) {
    try {
      const response = await fetch("/store/_api/cart/");
      if (!response.ok) {
        throw new Error(`Unexpected ${response.statusText} response (${response.status}) when fetching store cart`);
      }
      const cartObject = await response.json();
      updateCartIcons(cartObject.count);
    } catch (error) {
      if (retriesLeft > 0) {
        await new Promise(r => setTimeout(r, 2000));	// NOTE: sleep 2 seconds
        await updateCartIconsAsync(retriesLeft - 1);
      } else if (Sentry && Sentry.captureException) {
        Sentry.captureException(error);
      }
      return;
    }
  }
}
